@import './val';
body {
  padding: 0;
  margin: 0;
}
#root {
  margin: 0 auto;
}
@media screen and (max-width: $mobile-width) {
  #root {
    overflow: hidden;
    padding-bottom: $mobile-tools-height;
  }
}

.ant-menu-item-selected,
.ant-menu-submenu-selected {
  background-color: $theme !important;
}
.ant-menu-submenu-selected:hover {
  color: $select-color !important;
}
.ant-menu-item > a {
  color: $theme !important;
}
.ant-menu-item .ant-menu-item-selected > a {
  color: $select-color !important;
}
.ant-menu-submenu:hover {
  color: $select-color !important;
}

.ant-menu-item-selected > a {
  color: $select-color !important;
}
.ant-menu-sub {
  background-color: $select-color !important;
}
.ant-menu-item:hover {
  background-color: $theme !important;
  > a {
    color: $select-color !important;
  }
}

.ant-menu-submenu-title {
  color: $theme !important;
}

.ant-menu-submenu:hover {
  background-color: $theme !important;
  .ant-menu-submenu-title {
    color: $select-color !important;
  }
}

.ant-menu-submenu-selected {
  .ant-menu-submenu-title {
    color: $select-color !important;
  }
}

::selection {
  background-color: $theme;
  color: $select-color;
}

[icon] {
  pointer-events: none;
  user-select: none;
}
