$theme: #46474c;
$select-color: #eee;

$page-width: 1280px;

$banner-height: 436px;

// header start
$header-height: 78px;

// header end

// footer start
$footer-height: 192px;
// footer end

$mobile-width: 767px;

$mobile-tools-height: 50px;
