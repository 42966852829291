@import '@/style/tool';
@media screen and (max-width: $mobile-width) {
  .container {
    display: flex !important;
  }
}
.container {
  display: none;
  position: fixed;
  align-items: center;
  text-align: center;
  width: 100%;
  bottom: 0;
  height: $mobile-tools-height;
  background-color: rgb(76, 76, 76);
  font-size: 12px;
  a {
    color: #fff;
    flex: 1;
  }
  a ~ a {
    border-left: 1px solid #fff;
  }
}

.iconService,
.iconPhone {
  margin-right: 8px;
  width: 14px;
  height: 14px;
}
